import { string } from 'prop-types';
import React from 'react';
import { RedAdterisk } from '../../../../../components';

export const RequiredLabel = props => {
  const { label } = props;
  return (
    <span>
      {label} <RedAdterisk />
    </span>
  );
};

RequiredLabel.propTypes = {
  label: string,
};
